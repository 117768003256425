import React, { useEffect } from 'react'
import Form from '../form';
import './contact.css';
import { useForm } from "react-hook-form";
import _ from "lodash";
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';




function Contact(props) {
    const { register, handleSubmit, watch, errors } = useForm();
    let history = useHistory();
    // console.log("History====>>>",history)
    const onSubmit = (data, e) => {
        // console.log(data)
        axios.post('https://www.3phtechsolutions.com/api/v1/about-us-mailer',data)
        .then((res)=>{
            
            // console.log("Res======>>>",res)
            if(res?.data?.status===1){
            e.target.reset()
            toast.success('Success!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            }
            if(res?.data?.status===0){
                toast.error('Message failed!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
            }

        })
        .catch((err)=>{
            // console.log("Error==>",err)
            toast.error('Some error with the server! Kindly wait while we fix it.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            
        })
        
    };
    // console.log(watch("example"));
    // console.log(errors);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="ct-container">
           
            <div className="ct-header">
                <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {/* Same as */}
            <ToastContainer />
                <h1>CONTACT US</h1>
            </div>
            <div className="contents">
                <div className="ct-content-left">
                    <div className="ct-row">
                        <h4><b>How Can We Help You ?</b></h4>
                        <span className="divider"></span>
                        <p>If you have any queries , please fill out this form</p>
                    </div>
                    <span className="ct-address">

                        <div className="adrs-column">
                            <h4>Corporate Office</h4><br />
                            {/* <p>GL-07, Kuber Avenue,<br />
                               Opp. Atithi Hotel, Seven Hills,<br />
                               High Court Road,<br/>
                               Aurangabad - 431001,<br/>
                               Maharashtra, India
                            </p> */}
                             <p>Kuber Avenue, Building 'A'- GL 7, <br />
                             Opposite Atithi Hotel, Seven Hills,<br />
                             Jalna Road, Ch Sambhaji Nagar(Aurangabad),<br />
                             Maharashtra-431001, India<br/>
                            </p>
                        </div>
                        <div className="ct-address">
                            <h4>Bengaluru</h4>
                            <span className="separator"></span>
                            <h4 style={{ width: '60px' }}>Kochi</h4>
                            <span className="separator"></span>
                            <h4 style={{ width: '54px' }}>Dubai</h4>
                            <span className="separator"></span>
                            <h4>Abu Dhabi</h4>
                        </div>
                    </span>
                    <span className="ct-contact">
                        <span className="ct-mail">
                            <h4>Email:</h4>
                            <p>sales@3phtechsolutions.com</p>
                        </span>
                        <span className="ct-ph">
                            <h4>Phone:</h4>
                            <div>
                                <p>+91-9188339981(India)</p>
                                <p>+971-557766524(UAE)</p>
                            </div>
                        </span>


                    </span>

                </div>
                <div className="ct-form">
                    <div className="form-wrapper">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <table>
                                <tbody>
                                <tr>
                                    <td className="txt-td">
                                        <input name="firstname" ref={register({ required: true, maxLength: 20 })} type="text" className="txt" placeholder="First Name" />

                                        {_.get("firstname.type", errors) === "required" && (
                                            <p className="validation">This field is required</p>
                                        )}
                                        {_.get("firstname.type", errors) === "maxLength" && (
                                            <p className="validation">First name cannot exceed 20 characters</p>
                                        )}
                                    </td>
                                    <td>
                                        <input name="lastname" ref={register({ required: true, maxLength: 20 })} type="text" className="txt" placeholder="Last Name" />
                                        {_.get("lastname.type", errors) === "required" && (
                                            <p className="validation">This field is required</p>
                                        )}
                                        {_.get("lastname.type", errors) === "maxLength" && (
                                            <p className="validation">First name cannot exceed 20 characters</p>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="mail-holder">
                                        <input name="mail" ref={register({ required: true, pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}
                                            type="mail" className="mail" placeholder="mail-Id" />
                                        {_.get("mail.type", errors) === "required" && (
                                            <p className="validation">This field is required</p>)}
                                        {_.get("mail.type", errors) === "pattern" && (
                                            <p className="validation">Invalid email!</p>)}
                                    </td>

                                </tr>
                                <tr>
                                    <td className="query-holder">
                                        <textarea ref={register({ required: true, maxLength: 200 })} name="message" placeholder="Your Queries Here..."></textarea>
                                        {_.get("message.type", errors) === "required" && (
                                            <p className="validation">This field is required</p>)}
                                        {_.get("message.type", errors) === "maxLength" && (
                                            <p className="validation">Maximum length exceeded!</p>)}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="button-holder">
                                        <button type="submit" className="send-btn">Send</button>
                                    </td>

                                </tr>
                                </tbody>
                            </table>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact;
