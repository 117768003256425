// import logo from './logo.svg';
import './App.css';
import Navi from './components/navigation/navi';
import Footer from './components/footer/footer';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
// import 'semantic-ui-css/semantic.min.css'
import Contact from './components/contact/contact';
import { Switch, Route } from 'react-router-dom';
import Davas from './components/davas/davas';
import Main from './components/main/main';
import ScrollHandler from './components/navigation/scrollHandler';
import Energy from './components/energy/energy';
import Water from './components/water/water';
import Asset from './components/asset/asset';
import Temp from './components/temperature/temp';
import Custom from './components/custom-solutions/custom';
import Etap from './components/etap/powersystem';
import Products from './components/products/products';
import SplEngi from './components/spl-engineering/spl-engi';
import Ems from './components/ems/ems';
import Facility from './components/facility/facility';



function App() {
  return (
    <div className="app-container">
      <Navi />
      <ScrollHandler />
      <Switch>
        <Route path='/' exact component={Main} />
        <Route path='/contact' exact component={Contact} />
        <Route path="/davas" exact component={Davas} />
        <Route path="/energy" exact component={Energy} />
        <Route path="/water" exact component={Water} />
        <Route path="/asset" exact component={Asset} />
        <Route path="/temp" exact component={Temp} />
        <Route path="/custom" exact component={Custom} />
        <Route path="/etap" exact component={Etap} />
        <Route path="/service" exact component={SplEngi} />
        <Route path="/products" exact component={Products} />
        <Route path="/ems" exact component={Ems} />
        <Route path="/facility" exact component={Facility} />
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
